import React, {Fragment, useCallback, useContext, useMemo} from 'react';
import styled from 'styled-components';
import {Breakpoints, Context, NavActions} from '../../Contexts/AppContext';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft} from '../../Components/SvgIcon';
import {useDimension} from '../../Hooks/AppHooks';
import {AboutBrandContext} from '../../Domain/Help';
import FixedRatioImage from '../../Components/FixedRatioImage';
import agt from '../../images/about/agt.jpg';
import IM from '../../images/about/IM.jpg';
import KNZ from '../../images/about/KNZ.jpg';
import LNZ from '../../images/about/LNS.jpg';
import SP from '../../images/about/SP.jpg';

const AboutTheBrandPage = props => {
  const app = useContext(Context);
  const {desktop, tablet} = useDimension();

  const style = useMemo(() => {
    if (app.activeBrand === 'self-portrait') {
      return {
        ratio: 938 / 1358,
      };
    } else if (app.activeBrand === 'les-nereides') {
      return {
        ratio: 970 / 1358,
      };
    } else {
      return {
        ratio: 960 / 1340,
      };
    }
  }, [app.activeBrand]);

  const bannerContext = {
    kenzo: KNZ,
    'self-portrait': SP,
    agete: agt,
    'isabel-marant': IM,
    'les-nereides': LNZ,
  };

  if (!app.homeData) {
    return null;
  }

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>關於品牌</div>}
        />
      )}
      <FixedRatioImage
        ratio={style.ratio}
        mode="cover"
        width="100%"
        image={bannerContext[app.activeBrand]}
      />
      <Wrapper>
        <div className="right">
          <div className="header">關於品牌</div>
          {AboutBrandContext[app.activeBrand].map((s, index) => (
            <section key={index} className="section">
              <div className="title">{s.title}</div>
              {s.data.map((c, idx) => (
                <Fragment key={idx}>
                  {c.context.map((ct, index) => (
                    <div key={index} className="content">
                      {ct}
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}>
                    {c.images.map((img, index) => {
                      return (
                        <FixedRatioImage
                          key={index}
                          image={img.source}
                          ratio={img.ratio}
                          mode="cover"
                          width="100%"
                          extraCss={{
                            marginBottom: 24,
                          }}
                          alt={`img-${index}`}
                        />
                      );
                    })}
                  </div>
                </Fragment>
              ))}
            </section>
          ))}
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;
  & > .right {
    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 32px;
    }
    & > .section {
      margin-bottom: 32px;
      & > .title {
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        margin-bottom: 8px;
      }
      & > .content {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        margin: 12px 0;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      & > .header {
        display: none;
      }
    }
  }
`;

export default AboutTheBrandPage;
